/* tslint:disable */
/* eslint-disable */
/**
 * Schedu
 * Student-friendly Assignments and Deadlines Management System
 *
 * The version of the OpenAPI document: 1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {Configuration} from './configuration';
import globalAxios, {AxiosInstance, AxiosPromise, AxiosRequestConfig} from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  assertParamExists,
  createRequestFunction,
  DUMMY_BASE_URL,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString
} from './common';
// @ts-ignore
import { BASE_PATH, BaseAPI, COLLECTION_FORMATS, RequestArgs, RequiredError } from "./base";

/**
 * 
 * @export
 * @interface Assignment
 */
export interface Assignment {
    /**
     * 
     * @type {number}
     * @memberof Assignment
     */
    'assignment_id': number;
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    'deadline': string;
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface AssignmentCreatedByUserAddPost200Response
 */
export interface AssignmentCreatedByUserAddPost200Response {
    /**
     * 
     * @type {number}
     * @memberof AssignmentCreatedByUserAddPost200Response
     */
    'insertion_id'?: number;
    /**
     * 
     * @type {UserCreatedAssignment}
     * @memberof AssignmentCreatedByUserAddPost200Response
     */
    'insertion_data'?: UserCreatedAssignment;
}
/**
 * 
 * @export
 * @interface AssignmentRequest
 */
export interface AssignmentRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignmentRequest
     */
    'deadline': string;
    /**
     * 
     * @type {string}
     * @memberof AssignmentRequest
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface AssignmentsAddPost200Response
 */
export interface AssignmentsAddPost200Response {
    /**
     * 
     * @type {number}
     * @memberof AssignmentsAddPost200Response
     */
    'insertion_id'?: number;
    /**
     * 
     * @type {Assignment}
     * @memberof AssignmentsAddPost200Response
     */
    'insertion_data'?: Assignment;
}
/**
 * 
 * @export
 * @interface AssignmentsAllGet200Response
 */
export interface AssignmentsAllGet200Response {
    /**
     * 
     * @type {Array<Assignment>}
     * @memberof AssignmentsAllGet200Response
     */
    'response': Array<Assignment>;
}
/**
 * 
 * @export
 * @interface DelayedAssignment
 */
export interface DelayedAssignment {
    /**
     * 
     * @type {number}
     * @memberof DelayedAssignment
     */
    'assignment_id': number;
    /**
     * 
     * @type {string}
     * @memberof DelayedAssignment
     */
    'publication_date': string;
}
/**
 * 
 * @export
 * @interface DelayedAssignmentsAddPost200Response
 */
export interface DelayedAssignmentsAddPost200Response {
    /**
     * 
     * @type {number}
     * @memberof DelayedAssignmentsAddPost200Response
     */
    'insertion_id'?: number;
    /**
     * 
     * @type {DelayedAssignment}
     * @memberof DelayedAssignmentsAddPost200Response
     */
    'insertion_data'?: DelayedAssignment;
}
/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {number}
     * @memberof Group
     */
    'group_id': number;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface GroupRequest
 */
export interface GroupRequest {
    /**
     * 
     * @type {string}
     * @memberof GroupRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GroupRequest
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface GroupsAddPost200Response
 */
export interface GroupsAddPost200Response {
    /**
     * 
     * @type {number}
     * @memberof GroupsAddPost200Response
     */
    'insertion_id'?: number;
    /**
     * 
     * @type {Group}
     * @memberof GroupsAddPost200Response
     */
    'insertion_data'?: Group;
}
/**
 * 
 * @export
 * @interface GroupsAllGet200Response
 */
export interface GroupsAllGet200Response {
    /**
     * 
     * @type {Array<Group>}
     * @memberof GroupsAllGet200Response
     */
    'response': Array<Group>;
}
/**
 * 
 * @export
 * @interface InsertionResult
 */
export interface InsertionResult {
    /**
     * 
     * @type {number}
     * @memberof InsertionResult
     */
    'inserted_id': number;
    /**
     * 
     * @type {object}
     * @memberof InsertionResult
     */
    'inserted_data': object;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface Member
 */
export interface Member {
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    'user_id': number;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    'group_id': number;
}
/**
 * 
 * @export
 * @interface Permission
 */
export interface Permission {
    /**
     * 
     * @type {number}
     * @memberof Permission
     */
    'permission_id': number;
    /**
     * 
     * @type {string}
     * @memberof Permission
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Permission
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof Permission
     */
    'type': number;
}
/**
 * 
 * @export
 * @interface PermissionRequest
 */
export interface PermissionRequest {
    /**
     * 
     * @type {string}
     * @memberof PermissionRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionRequest
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof PermissionRequest
     */
    'type': number;
}
/**
 * 
 * @export
 * @interface PermissionsAddPost200Response
 */
export interface PermissionsAddPost200Response {
    /**
     * 
     * @type {number}
     * @memberof PermissionsAddPost200Response
     */
    'insertion_id'?: number;
    /**
     * 
     * @type {Permission}
     * @memberof PermissionsAddPost200Response
     */
    'insertion_data'?: Permission;
}
/**
 * 
 * @export
 * @interface PermissionsAllGet200Response
 */
export interface PermissionsAllGet200Response {
    /**
     * 
     * @type {Array<Permission>}
     * @memberof PermissionsAllGet200Response
     */
    'response': Array<Permission>;
}
/**
 * 
 * @export
 * @interface PublicGroup
 */
export interface PublicGroup {
    /**
     * 
     * @type {number}
     * @memberof PublicGroup
     */
    'group_id': number;
    /**
     * 
     * @type {number}
     * @memberof PublicGroup
     */
    'default_role_id': number;
}
/**
 * 
 * @export
 * @interface PublicGroupsAddPost200Response
 */
export interface PublicGroupsAddPost200Response {
    /**
     * 
     * @type {number}
     * @memberof PublicGroupsAddPost200Response
     */
    'insertion_id'?: number;
    /**
     * 
     * @type {PublicGroup}
     * @memberof PublicGroupsAddPost200Response
     */
    'insertion_data'?: PublicGroup;
}
/**
 * 
 * @export
 * @interface PublicGroupsAllGet200Response
 */
export interface PublicGroupsAllGet200Response {
    /**
     * 
     * @type {Array<PublicGroup>}
     * @memberof PublicGroupsAllGet200Response
     */
    'response': Array<PublicGroup>;
}
/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    'role_id': number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface RoleRequest
 */
export interface RoleRequest {
    /**
     * 
     * @type {string}
     * @memberof RoleRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RoleRequest
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface RolesAddPost200Response
 */
export interface RolesAddPost200Response {
    /**
     * 
     * @type {number}
     * @memberof RolesAddPost200Response
     */
    'insertion_id'?: number;
    /**
     * 
     * @type {Role}
     * @memberof RolesAddPost200Response
     */
    'insertion_data'?: Role;
}
/**
 * 
 * @export
 * @interface RolesAllGet200Response
 */
export interface RolesAllGet200Response {
  /**
   *
   * @type {Array<Role>}
   * @memberof RolesAllGet200Response
   */
  'response': Array<Role>;
}

/**
 *
 * @export
 * @interface ServiceStatusGet200Response
 */
export interface ServiceStatusGet200Response {
  /**
   *
   * @type {string}
   * @memberof ServiceStatusGet200Response
   */
  'State': string;
  /**
   *
   * @type {string}
   * @memberof ServiceStatusGet200Response
   */
  'API version': string;
  /**
   *
   * @type {number}
   * @memberof ServiceStatusGet200Response
   */
  'Amount of connections': number;
}

/**
 *
 * @export
 * @interface TemporaryRole
 */
export interface TemporaryRole {
  /**
   *
   * @type {number}
   * @memberof TemporaryRole
   */
  'role_id': number;
  /**
   *
   * @type {string}
   * @memberof TemporaryRole
   */
  'expiry_date': string;
}
/**
 * 
 * @export
 * @interface TemporaryRolesAddPost200Response
 */
export interface TemporaryRolesAddPost200Response {
    /**
     * 
     * @type {number}
     * @memberof TemporaryRolesAddPost200Response
     */
    'insertion_id'?: number;
    /**
     * 
     * @type {TemporaryRole}
     * @memberof TemporaryRolesAddPost200Response
     */
    'insertion_data'?: TemporaryRole;
}
/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'Token': string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'user_id': number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'password_hash': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'graduation_year'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface UserCompletedAssignment
 */
export interface UserCompletedAssignment {
    /**
     * 
     * @type {number}
     * @memberof UserCompletedAssignment
     */
    'user_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserCompletedAssignment
     */
    'assignment_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserCompletedAssignment
     */
    'timestamp'?: string;
}
/**
 * 
 * @export
 * @interface UserCreatedAssignment
 */
export interface UserCreatedAssignment {
    /**
     * 
     * @type {number}
     * @memberof UserCreatedAssignment
     */
    'user_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserCreatedAssignment
     */
    'assignment_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserCreatedAssignment
     */
    'timestamp'?: string;
}
/**
 * 
 * @export
 * @interface UserHasCompletedAssignmentAddPost200Response
 */
export interface UserHasCompletedAssignmentAddPost200Response {
    /**
     * 
     * @type {number}
     * @memberof UserHasCompletedAssignmentAddPost200Response
     */
    'insertion_id'?: number;
    /**
     * 
     * @type {UserCompletedAssignment}
     * @memberof UserHasCompletedAssignmentAddPost200Response
     */
    'insertion_data'?: UserCompletedAssignment;
}
/**
 * 
 * @export
 * @interface UserHasRole
 */
export interface UserHasRole {
    /**
     * 
     * @type {number}
     * @memberof UserHasRole
     */
    'user_id': number;
    /**
     * 
     * @type {number}
     * @memberof UserHasRole
     */
    'role_id': number;
}
/**
 * 
 * @export
 * @interface UserHasRoleAddPost200Response
 */
export interface UserHasRoleAddPost200Response {
    /**
     * 
     * @type {number}
     * @memberof UserHasRoleAddPost200Response
     */
    'insertion_id'?: number;
    /**
     * 
     * @type {UserHasRole}
     * @memberof UserHasRoleAddPost200Response
     */
    'insertion_data'?: UserHasRole;
}
/**
 * 
 * @export
 * @interface UserMemberOfGroupAddPost200Response
 */
export interface UserMemberOfGroupAddPost200Response {
    /**
     * 
     * @type {number}
     * @memberof UserMemberOfGroupAddPost200Response
     */
    'insertion_id'?: number;
    /**
     * 
     * @type {Member}
     * @memberof UserMemberOfGroupAddPost200Response
     */
    'insertion_data'?: Member;
}
/**
 * 
 * @export
 * @interface UserRequest
 */
export interface UserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    'password_hash': string;
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    'email'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserRequest
     */
    'graduation_year'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface UsersAddPost200Response
 */
export interface UsersAddPost200Response {
    /**
     * 
     * @type {number}
     * @memberof UsersAddPost200Response
     */
    'insertion_id'?: number;
    /**
     * 
     * @type {User}
     * @memberof UsersAddPost200Response
     */
    'insertion_data'?: User;
}
/**
 * 
 * @export
 * @interface UsersAllGet200Response
 */
export interface UsersAllGet200Response {
    /**
     * 
     * @type {Array<User>}
     * @memberof UsersAllGet200Response
     */
    'response': Array<User>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
      /**
       *
       * @summary Create a New Completed
       * @param {UserCreatedAssignment} [userCreatedAssignment]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      assignmentCreatedByUserAddPost: async (
        userCreatedAssignment?: UserCreatedAssignment,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        const localVarPath = `/assignment_created_by_user/add`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication MarkToken required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration);

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
        localVarRequestOptions.data = serializeDataIfNeeded(
          userCreatedAssignment,
          localVarRequestOptions,
          configuration
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Create a New Assignment
       * @param {AssignmentRequest} [assignmentRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      assignmentsAddPost: async (
        assignmentRequest?: AssignmentRequest,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        const localVarPath = `/assignments/add`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication MarkToken required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration);

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
        localVarRequestOptions.data = serializeDataIfNeeded(assignmentRequest, localVarRequestOptions, configuration);

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      assignmentsAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
        const localVarPath = `/assignments/all`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Create a New Delayed Assignments
       * @param {DelayedAssignment} [delayedAssignment]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      delayedAssignmentsAddPost: async (
        delayedAssignment?: DelayedAssignment,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        const localVarPath = `/delayed_assignments/add`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication MarkToken required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration);

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
        localVarRequestOptions.data = serializeDataIfNeeded(delayedAssignment, localVarRequestOptions, configuration);

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Create a New Group
       * @param {GroupRequest} [groupRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      groupsAddPost: async (groupRequest?: GroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
        const localVarPath = `/groups/add`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication MarkToken required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration);

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
        localVarRequestOptions.data = serializeDataIfNeeded(groupRequest, localVarRequestOptions, configuration);

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary List all groups
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      groupsAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
        const localVarPath = `/groups/all`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Create a New Permission
       * @param {PermissionRequest} [permissionRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      permissionsAddPost: async (
        permissionRequest?: PermissionRequest,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        const localVarPath = `/permissions/add`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication MarkToken required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration);

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
        localVarRequestOptions.data = serializeDataIfNeeded(permissionRequest, localVarRequestOptions, configuration);

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      permissionsAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
        const localVarPath = `/permissions/all`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Create a New Public Group
       * @param {PublicGroup} [publicGroup]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      publicGroupsAddPost: async (
        publicGroup?: PublicGroup,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        const localVarPath = `/public_groups/add`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication MarkToken required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration);

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
        localVarRequestOptions.data = serializeDataIfNeeded(publicGroup, localVarRequestOptions, configuration);

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      publicGroupsAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
        const localVarPath = `/public_groups/all`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Create a New Role
       * @param {RoleRequest} [roleRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      rolesAddPost: async (roleRequest?: RoleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
        const localVarPath = `/roles/add`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication MarkToken required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration);

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
        localVarRequestOptions.data = serializeDataIfNeeded(roleRequest, localVarRequestOptions, configuration);

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      rolesAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
        const localVarPath = `/roles/all`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Check server\'s health
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      serviceStatusGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
        const localVarPath = `/service/status`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Create a New Temporary role
       * @param {TemporaryRole} [temporaryRole]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      temporaryRolesAddPost: async (
        temporaryRole?: TemporaryRole,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        const localVarPath = `/temporary_roles/add`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication MarkToken required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration);

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
        localVarRequestOptions.data = serializeDataIfNeeded(temporaryRole, localVarRequestOptions, configuration);

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Create a New Completed
       * @param {UserCompletedAssignment} [userCompletedAssignment]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      userHasCompletedAssignmentAddPost: async (
        userCompletedAssignment?: UserCompletedAssignment,
        options: AxiosRequestConfig = {}
      ): Promise<RequestArgs> => {
        const localVarPath = `/user_has_completed_assignment/add`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication MarkToken required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration);

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
        localVarRequestOptions.data = serializeDataIfNeeded(
          userCompletedAssignment,
          localVarRequestOptions,
          configuration
        );

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Create a New UserHasRole
       * @param {UserHasRole} [userHasRole]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      userHasRoleAddPost: async (userHasRole?: UserHasRole, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
        const localVarPath = `/user_has_role/add`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication MarkToken required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration);

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
        localVarRequestOptions.data = serializeDataIfNeeded(userHasRole, localVarRequestOptions, configuration);

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Get token for using guarded methods
       * @param {LoginRequest} [loginRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      userLoginPost: async (loginRequest?: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
        const localVarPath = `/user/login`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
        localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration);

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Create a Member
       * @param {Member} [member]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      userMemberOfGroupAddPost: async (member?: Member, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
        const localVarPath = `/user_member_of_group/add`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication MarkToken required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration);

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
        localVarRequestOptions.data = serializeDataIfNeeded(member, localVarRequestOptions, configuration);

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary Create a New User
       * @param {UserRequest} [userRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      usersAddPost: async (userRequest?: UserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
        const localVarPath = `/users/add`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        // authentication MarkToken required
        // http bearer authentication required
        await setBearerAuthToObject(localVarHeaderParameter, configuration);

        localVarHeaderParameter["Content-Type"] = "application/json";

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
        localVarRequestOptions.data = serializeDataIfNeeded(userRequest, localVarRequestOptions, configuration);

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
      /**
       *
       * @summary List all Users
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      usersAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
        const localVarPath = `/users/all`;
        // use dummy base URL string because the URL constructor only accepts absolute URLs.
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
          baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;

        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

        return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
        };
      },
    };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
      /**
       *
       * @summary Create a New Completed
       * @param {UserCreatedAssignment} [userCreatedAssignment]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async assignmentCreatedByUserAddPost(
        userCreatedAssignment?: UserCreatedAssignment,
        options?: AxiosRequestConfig
      ): Promise<
        (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentCreatedByUserAddPost200Response>
      > {
        const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentCreatedByUserAddPost(
          userCreatedAssignment,
          options
        );
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
      },
      /**
       *
       * @summary Create a New Assignment
       * @param {AssignmentRequest} [assignmentRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async assignmentsAddPost(
        assignmentRequest?: AssignmentRequest,
        options?: AxiosRequestConfig
      ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentsAddPost200Response>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentsAddPost(assignmentRequest, options);
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
      },
      /**
       *
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async assignmentsAllGet(
        options?: AxiosRequestConfig
      ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentsAllGet200Response>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentsAllGet(options);
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
      },
      /**
       *
       * @summary Create a New Delayed Assignments
       * @param {DelayedAssignment} [delayedAssignment]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async delayedAssignmentsAddPost(
        delayedAssignment?: DelayedAssignment,
        options?: AxiosRequestConfig
      ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DelayedAssignmentsAddPost200Response>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.delayedAssignmentsAddPost(delayedAssignment, options);
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
      },
      /**
       *
       * @summary Create a New Group
       * @param {GroupRequest} [groupRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async groupsAddPost(
        groupRequest?: GroupRequest,
        options?: AxiosRequestConfig
      ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupsAddPost200Response>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.groupsAddPost(groupRequest, options);
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
      },
      /**
       *
       * @summary List all groups
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async groupsAllGet(
        options?: AxiosRequestConfig
      ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupsAllGet200Response>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.groupsAllGet(options);
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
      },
      /**
       *
       * @summary Create a New Permission
       * @param {PermissionRequest} [permissionRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async permissionsAddPost(
        permissionRequest?: PermissionRequest,
        options?: AxiosRequestConfig
      ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionsAddPost200Response>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.permissionsAddPost(permissionRequest, options);
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
      },
      /**
       *
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async permissionsAllGet(
        options?: AxiosRequestConfig
      ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermissionsAllGet200Response>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.permissionsAllGet(options);
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
      },
      /**
       *
       * @summary Create a New Public Group
       * @param {PublicGroup} [publicGroup]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async publicGroupsAddPost(
        publicGroup?: PublicGroup,
        options?: AxiosRequestConfig
      ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicGroupsAddPost200Response>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.publicGroupsAddPost(publicGroup, options);
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
      },
      /**
       *
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async publicGroupsAllGet(
        options?: AxiosRequestConfig
      ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicGroupsAllGet200Response>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.publicGroupsAllGet(options);
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
      },
      /**
       *
       * @summary Create a New Role
       * @param {RoleRequest} [roleRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async rolesAddPost(
        roleRequest?: RoleRequest,
        options?: AxiosRequestConfig
      ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RolesAddPost200Response>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.rolesAddPost(roleRequest, options);
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
      },
      /**
       *
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async rolesAllGet(
        options?: AxiosRequestConfig
      ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RolesAllGet200Response>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.rolesAllGet(options);
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
      },
      /**
       *
       * @summary Check server\'s health
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async serviceStatusGet(
        options?: AxiosRequestConfig
      ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceStatusGet200Response>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.serviceStatusGet(options);
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
      },
      /**
       *
       * @summary Create a New Temporary role
       * @param {TemporaryRole} [temporaryRole]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async temporaryRolesAddPost(
        temporaryRole?: TemporaryRole,
        options?: AxiosRequestConfig
      ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemporaryRolesAddPost200Response>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.temporaryRolesAddPost(temporaryRole, options);
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
      },
      /**
       *
       * @summary Create a New Completed
       * @param {UserCompletedAssignment} [userCompletedAssignment]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async userHasCompletedAssignmentAddPost(
        userCompletedAssignment?: UserCompletedAssignment,
        options?: AxiosRequestConfig
      ): Promise<
        (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserHasCompletedAssignmentAddPost200Response>
      > {
        const localVarAxiosArgs = await localVarAxiosParamCreator.userHasCompletedAssignmentAddPost(
          userCompletedAssignment,
          options
        );
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
      },
      /**
       *
       * @summary Create a New UserHasRole
       * @param {UserHasRole} [userHasRole]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async userHasRoleAddPost(
        userHasRole?: UserHasRole,
        options?: AxiosRequestConfig
      ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserHasRoleAddPost200Response>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.userHasRoleAddPost(userHasRole, options);
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
      },
      /**
       *
       * @summary Get token for using guarded methods
       * @param {LoginRequest} [loginRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async userLoginPost(
        loginRequest?: LoginRequest,
        options?: AxiosRequestConfig
      ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.userLoginPost(loginRequest, options);
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
      },
      /**
       *
       * @summary Create a Member
       * @param {Member} [member]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async userMemberOfGroupAddPost(
        member?: Member,
        options?: AxiosRequestConfig
      ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserMemberOfGroupAddPost200Response>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.userMemberOfGroupAddPost(member, options);
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
      },
      /**
       *
       * @summary Create a New User
       * @param {UserRequest} [userRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async usersAddPost(
        userRequest?: UserRequest,
        options?: AxiosRequestConfig
      ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersAddPost200Response>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.usersAddPost(userRequest, options);
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
      },
      /**
       *
       * @summary List all Users
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      async usersAllGet(
        options?: AxiosRequestConfig
      ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersAllGet200Response>> {
        const localVarAxiosArgs = await localVarAxiosParamCreator.usersAllGet(options);
        return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
      },
    };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
      /**
       *
       * @summary Create a New Completed
       * @param {UserCreatedAssignment} [userCreatedAssignment]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      assignmentCreatedByUserAddPost(
        userCreatedAssignment?: UserCreatedAssignment,
        options?: any
      ): AxiosPromise<AssignmentCreatedByUserAddPost200Response> {
        return localVarFp
          .assignmentCreatedByUserAddPost(userCreatedAssignment, options)
          .then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Create a New Assignment
       * @param {AssignmentRequest} [assignmentRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      assignmentsAddPost(
        assignmentRequest?: AssignmentRequest,
        options?: any
      ): AxiosPromise<AssignmentsAddPost200Response> {
        return localVarFp.assignmentsAddPost(assignmentRequest, options).then((request) => request(axios, basePath));
      },
      /**
       *
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      assignmentsAllGet(options?: any): AxiosPromise<AssignmentsAllGet200Response> {
        return localVarFp.assignmentsAllGet(options).then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Create a New Delayed Assignments
       * @param {DelayedAssignment} [delayedAssignment]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      delayedAssignmentsAddPost(
        delayedAssignment?: DelayedAssignment,
        options?: any
      ): AxiosPromise<DelayedAssignmentsAddPost200Response> {
        return localVarFp
          .delayedAssignmentsAddPost(delayedAssignment, options)
          .then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Create a New Group
       * @param {GroupRequest} [groupRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      groupsAddPost(groupRequest?: GroupRequest, options?: any): AxiosPromise<GroupsAddPost200Response> {
        return localVarFp.groupsAddPost(groupRequest, options).then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary List all groups
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      groupsAllGet(options?: any): AxiosPromise<GroupsAllGet200Response> {
        return localVarFp.groupsAllGet(options).then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Create a New Permission
       * @param {PermissionRequest} [permissionRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      permissionsAddPost(
        permissionRequest?: PermissionRequest,
        options?: any
      ): AxiosPromise<PermissionsAddPost200Response> {
        return localVarFp.permissionsAddPost(permissionRequest, options).then((request) => request(axios, basePath));
      },
      /**
       *
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      permissionsAllGet(options?: any): AxiosPromise<PermissionsAllGet200Response> {
        return localVarFp.permissionsAllGet(options).then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Create a New Public Group
       * @param {PublicGroup} [publicGroup]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      publicGroupsAddPost(publicGroup?: PublicGroup, options?: any): AxiosPromise<PublicGroupsAddPost200Response> {
        return localVarFp.publicGroupsAddPost(publicGroup, options).then((request) => request(axios, basePath));
      },
      /**
       *
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      publicGroupsAllGet(options?: any): AxiosPromise<PublicGroupsAllGet200Response> {
        return localVarFp.publicGroupsAllGet(options).then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Create a New Role
       * @param {RoleRequest} [roleRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      rolesAddPost(roleRequest?: RoleRequest, options?: any): AxiosPromise<RolesAddPost200Response> {
        return localVarFp.rolesAddPost(roleRequest, options).then((request) => request(axios, basePath));
      },
      /**
       *
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      rolesAllGet(options?: any): AxiosPromise<RolesAllGet200Response> {
        return localVarFp.rolesAllGet(options).then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Check server\'s health
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      serviceStatusGet(options?: any): AxiosPromise<ServiceStatusGet200Response> {
        return localVarFp.serviceStatusGet(options).then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Create a New Temporary role
       * @param {TemporaryRole} [temporaryRole]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      temporaryRolesAddPost(
        temporaryRole?: TemporaryRole,
        options?: any
      ): AxiosPromise<TemporaryRolesAddPost200Response> {
        return localVarFp.temporaryRolesAddPost(temporaryRole, options).then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Create a New Completed
       * @param {UserCompletedAssignment} [userCompletedAssignment]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      userHasCompletedAssignmentAddPost(
        userCompletedAssignment?: UserCompletedAssignment,
        options?: any
      ): AxiosPromise<UserHasCompletedAssignmentAddPost200Response> {
        return localVarFp
          .userHasCompletedAssignmentAddPost(userCompletedAssignment, options)
          .then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Create a New UserHasRole
       * @param {UserHasRole} [userHasRole]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      userHasRoleAddPost(userHasRole?: UserHasRole, options?: any): AxiosPromise<UserHasRoleAddPost200Response> {
        return localVarFp.userHasRoleAddPost(userHasRole, options).then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Get token for using guarded methods
       * @param {LoginRequest} [loginRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      userLoginPost(loginRequest?: LoginRequest, options?: any): AxiosPromise<Token> {
        return localVarFp.userLoginPost(loginRequest, options).then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Create a Member
       * @param {Member} [member]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      userMemberOfGroupAddPost(member?: Member, options?: any): AxiosPromise<UserMemberOfGroupAddPost200Response> {
        return localVarFp.userMemberOfGroupAddPost(member, options).then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary Create a New User
       * @param {UserRequest} [userRequest]
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      usersAddPost(userRequest?: UserRequest, options?: any): AxiosPromise<UsersAddPost200Response> {
        return localVarFp.usersAddPost(userRequest, options).then((request) => request(axios, basePath));
      },
      /**
       *
       * @summary List all Users
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
      usersAllGet(options?: any): AxiosPromise<UsersAllGet200Response> {
        return localVarFp.usersAllGet(options).then((request) => request(axios, basePath));
      },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary Create a New Completed
   * @param {UserCreatedAssignment} [userCreatedAssignment]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public assignmentCreatedByUserAddPost(userCreatedAssignment?: UserCreatedAssignment, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .assignmentCreatedByUserAddPost(userCreatedAssignment, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a New Assignment
   * @param {AssignmentRequest} [assignmentRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public assignmentsAddPost(assignmentRequest?: AssignmentRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .assignmentsAddPost(assignmentRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public assignmentsAllGet(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .assignmentsAllGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a New Delayed Assignments
   * @param {DelayedAssignment} [delayedAssignment]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public delayedAssignmentsAddPost(delayedAssignment?: DelayedAssignment, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .delayedAssignmentsAddPost(delayedAssignment, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a New Group
   * @param {GroupRequest} [groupRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public groupsAddPost(groupRequest?: GroupRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .groupsAddPost(groupRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List all groups
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public groupsAllGet(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .groupsAllGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a New Permission
   * @param {PermissionRequest} [permissionRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public permissionsAddPost(permissionRequest?: PermissionRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .permissionsAddPost(permissionRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public permissionsAllGet(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .permissionsAllGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a New Public Group
   * @param {PublicGroup} [publicGroup]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicGroupsAddPost(publicGroup?: PublicGroup, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .publicGroupsAddPost(publicGroup, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public publicGroupsAllGet(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .publicGroupsAllGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a New Role
   * @param {RoleRequest} [roleRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public rolesAddPost(roleRequest?: RoleRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .rolesAddPost(roleRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public rolesAllGet(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .rolesAllGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Check server\'s health
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public serviceStatusGet(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .serviceStatusGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a New Temporary role
   * @param {TemporaryRole} [temporaryRole]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public temporaryRolesAddPost(temporaryRole?: TemporaryRole, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .temporaryRolesAddPost(temporaryRole, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a New Completed
   * @param {UserCompletedAssignment} [userCompletedAssignment]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userHasCompletedAssignmentAddPost(
    userCompletedAssignment?: UserCompletedAssignment,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .userHasCompletedAssignmentAddPost(userCompletedAssignment, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a New UserHasRole
   * @param {UserHasRole} [userHasRole]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userHasRoleAddPost(userHasRole?: UserHasRole, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userHasRoleAddPost(userHasRole, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get token for using guarded methods
   * @param {LoginRequest} [loginRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userLoginPost(loginRequest?: LoginRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userLoginPost(loginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a Member
   * @param {Member} [member]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userMemberOfGroupAddPost(member?: Member, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userMemberOfGroupAddPost(member, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a New User
   * @param {UserRequest} [userRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersAddPost(userRequest?: UserRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .usersAddPost(userRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List all Users
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public usersAllGet(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .usersAllGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}


